<!--
    Created by 程雨喵'mac on 2023/12/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.merchant-detail {
  position: relative;
  padding: 0 @containerGap @containerGap;
  border-radius: @borderRadiusMid;
  overflow: hidden;
  background-color: @backColorStrong;
  .merchant-content {
    padding-top: @containerGap * 2;
    position: relative;
    transition: all @transitionTime25;
    .row-area {
      &+.row-area {
        margin-top: @containerGap * 2;
      }
      .h3 {
        position: relative;
        padding-left: 10px;
        margin-bottom: @containerGap;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          border-radius: 2px;
          background-color: @themeColor
        }
      }
      .info-table {
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        box-sizing: border-box;
        line-height: 17px;
        .info-table-row {
          padding: @containerGap * 0.5 0;
          .flex-grow(50%);
        }
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    :show-bottom="false"
    width="1000"
    :back-type="2"
    title="详情信息"
    @on-close="_close"
  >
    <div
      slot="content"
      class="merchant-detail"
    >
      <div
        class="merchant-content is-info-content"
      >
        <!--信息-->
        <div
          v-for="(row, r) in infoItems"
          :key="'row-' + r"
          class="row-area"
        >
          <div class="h3">
            {{ row.title }}
          </div>
          <div class="info-table">
            <div
              v-for="(item, i) in row.items"
              :key="i"
              class="info-table-row"
            >
              <yg-attribute-key-value
                :label="item.label"
                :label-width="120"
                :value="model[item.key]"
                :can-hide-phone="item.hidePhone"
                :can-copy="item.canCopy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      infoItems: [
        {
          title: '基本信息',
          items: [
            { label: '物业名称：', key: 'name', canCopy: false, hidePhone: false },
            { label: '物业编码：', key: 'id', canCopy: false, hidePhone: false },
            { label: '证件号码：', key: 'idCard', canCopy: true, hidePhone: false },
            { label: '联系电话：', key: 'tel', canCopy: false, hidePhone: true }
          ]
        },
        {
          title: '所在区域',
          items: [
            { label: '省份：', key: 'province', canCopy: true, hidePhone: false },
            { label: '城市：', key: 'city', canCopy: true, hidePhone: false },
            { label: '区域：', key: 'area', canCopy: true, hidePhone: false }
          ]
        },
        {
          title: '银行信息',
          items: [
            { label: '开户行：', key: 'branchBank', canCopy: false, hidePhone: false },
            { label: '手机号码：', key: 'tel', canCopy: false, hidePhone: true },
            { label: '银行账号：', key: 'bankNo', canCopy: true, hidePhone: false }
          ]
        }
      ]
    };
  },
  methods: {
    _close () {
      this.$emit('on-close');
    }
  }
};
</script>
