<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：商户管理-商户列表
-->
<style lang="less">
#xxxx {
  padding: @containerGap;
  .col-text-strong {
    color: @adornColor;
    font-weight: 600;
  }
  .rule-left-box {
    position: relative;
    height: 790px;
    width: 260px;
    //background: #ccc;
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .rule-left-box-inner {
      padding: 348px 0 415px;
      position: relative;
      top: 0;
      height: auto;
      transition: all @transitionTime25;
      .rule-left-box-inner-li {
        height: 87px;
        line-height: 87px;
        //font-size: 30px;
        transition: all @transitionTime25;
        &.is-act {
          font-size: 30px !important;
          opacity: 1 !important;
          font-weight: 600;
        }
      }
    }
  }
}
</style>

<template>
  <div id="xxxx">
    <!-- 查询条件 -->
    <div>
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div class="padding-primary-top">
      <div
        ref="ruleLeftBox"
        class="rule-left-box"
        @scroll="onScrollLeftBox"
        @touchend="onTouchEnd"
      >
        <div
          class="rule-left-box-inner"
        >
          <div
            v-for="(item, i) in dataList"
            :key="i"
            class="rule-left-box-inner-li word-row word-row-1"
            :class="i === dataIndex ? 'is-act' : ''"
            :style="getLiStyle(i)"
            @click.stop="_clickLeftType(i)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!--<yg-table-->
      <!--  :columns="tableColumns"-->
      <!--  :data="tableData"-->
      <!--  :page="page"-->
      <!--  @on-page-change="onChangePage"-->
      <!--/>-->
    </div>
    <!--弹窗：详情-->
    <v-detail
      v-if="!!dialog.detailModel"
      :model="dialog.detailModel"
      @on-close="dialog.detailModel = null"
    />
    <!--弹窗：提交(新增/编辑)-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      @on-close="dialog.submitModel = null"
      @on-sure="dialog.submitModel = null"
    />
  </div>
</template>

<script>
import { DemoListModel, DemoDetailModel } from './model/DemoDataModel';
import vSubmit from './view/submit.vue';
import vDetail from './view/detail.vue';
const CELL_HEIGHT = 87;
const smoothScrollTo = (ele, from, to, duration, isY = true) => {
  if (from === to) return;
  let currentDuration = duration;
  if (!currentDuration) {
    currentDuration = Math.abs(to - from);
  }
  const gap = Math.max(Math.abs(to - from) / currentDuration * 16.666, 1);
  let currentPosition = from;
  const animateHeader = function () {
    if (from > to) {
      currentPosition -= gap;
    } else {
      currentPosition += gap;
    }
    // 如果我们已经在正确的位置，停止动画
    if (currentPosition === to) {
      return;
    }
    // 容错要在10之内
    const wrongGap = Math.abs(currentPosition - to);
    if (wrongGap < 10) {
      currentPosition = to;
    }
    // 使用新的位置滚动
    if (isY) {
      ele.scrollTo(0, currentPosition);
    } else {
      ele.scrollTo(currentPosition, 0);
    }

    // 如果我们还没有到达目标位置，继续动画
    if (currentPosition !== to) {
      requestAnimationFrame(animateHeader);
    }
  };
  // 开始动画
  animateHeader();
};

let SCROLL_COUNT = 0;

export default {
  components: { vDetail, vSubmit },
  data () {
    return {
      dataList: [
        { name: 'TEST_PICKER_1' },
        { name: 'TEST_PICKER_2' },
        { name: 'TEST_PICKER_3' },
        { name: 'TEST_PICKER_4' },
        { name: 'TEST_PICKER_5' },
        { name: 'TEST_PICKER_6' },
        { name: 'TEST_PICKER_7' },
        { name: 'TEST_PICKER_8' },
        { name: 'TEST_PICKER_9' },
        { name: 'TEST_PICKER_10' },
        { name: 'TEST_PICKER_11' },
        { name: 'TEST_PICKER_12' },
        { name: 'TEST_PICKER_13' },
        { name: 'TEST_PICKER_14' },
        { name: 'TEST_PICKER_15' },
        { name: 'TEST_PICKER_16' },
        { name: 'TEST_PICKER_17' },
        { name: 'TEST_PICKER_18' },
        { name: 'TEST_PICKER_19' },
        { name: 'TEST_PICKER_20' }
      ],
      dataIndex: 0,
      searchOption: {
        tel: BaseSearchModel.initData('商户名称', 'tel', 'Input')
      },
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle'),
        BaseSettingModel.initData('下载', 'download', '')
      ],
      page: { current: 1, total: 0 },
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('name', '物业名称').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('id', '物业编码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('tel', '联系电话').setSpan({ canHidePhone: true }),
        BaseTableColumn.initData('area', '所在区域').setKeyValueList([
          { label: '省份：', labelWidth: 40, key: 'province', attr: { canCopy: true } },
          { label: '城市：', labelWidth: 40, key: 'city', attr: { canCopy: true } },
          { label: '区域：', labelWidth: 40, key: 'area', attr: { canCopy: true } }
        ]),
        BaseTableColumn.initData('mainPrice', '本月收入').setSpan({ className: 'col-text-strong', canPrice: true }),
        BaseTableColumn.initData('lightPrice', '本月支出').setSpan({ canPrice: true }),
        BaseTableColumn.initData('setting', '操作').setWidth(140).setAlign('center').setButtons([
          { title: '详情', name: 'detail' },
          { title: '编辑', name: 'edit' }
        ], ({ name }, row, index) => {
          if (name === 'detail') {
            this.requestDetail(row);
          } else if (name === 'edit') {
            this.dialog.submitModel = DemoListModel.deepCopy(row);
          }
        })
      ],
      tableData: [],
      dialog: {
        detailModel: null,
        submitModel: null
      }
    };
  },
  computed: {},
  created () {
    console.log(12 & 15);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    onTouchEnd (e) {
      // // console.log(e);
      // this.isScrolling = false;
      // if (this.clickScrolling) return;
      // const target = this.$refs.ruleLeftBox;
      // const t = target.scrollTop;
      // // console.log(t, t % CELL_HEIGHT);
      // if (t % CELL_HEIGHT !== 0) {
      //   target.scrollTo(0, this.dataIndex * CELL_HEIGHT);
      //   // smoothScrollTo(target, t, this.dataIndex * CELL_HEIGHT);
      //   console.log('校准位置 + ');
      //   if (!this.scrollNotRequest) {
      //     // this.requestDetail();
      //   }
      // } else if (t <= 0) {
      //   console.log('0位置');
      //   if (!this.scrollNotRequest) {
      //     // this.requestDetail();
      //   }
      // }
    },
    onScrollLeftBox (e) {
      console.log('scroll');
      if (this.scrollNotRequest) return;
      this.isScrolling = true;
      const { target } = e;
      const t = target.scrollTop;
      this.dataIndex = Math.floor(t / CELL_HEIGHT);
      SCROLL_COUNT += 1;
      setTimeout(() => {
        SCROLL_COUNT -= 1;
        if (SCROLL_COUNT <= 0) {
          target.scrollTo(0, this.dataIndex * CELL_HEIGHT);
          this.requestDetail();
        }
      }, 300);
    },
    _clickLeftType (i) {
      if (this.dataIndex !== i) {
        if (this.isScrolling) return;
        // this.$refs.ruleLeftBox.scrollTo(0, i * CELL_HEIGHT);
        // 这个会多次触发scrollEnd事件，因此 点击时不要请求数据
        this.scrollNotRequest = true;
        // this.isScrolling = true;
        smoothScrollTo(this.$refs.ruleLeftBox, this.$refs.ruleLeftBox.scrollTop, i * CELL_HEIGHT, 50);
        setTimeout(() => {
          this.scrollNotRequest = false;
          this.dataIndex = i;
          this.requestDetail();
        }, 150);
        // 这个会多次触发scrollEnd事件，因此 点击时不要请求数据
        // this.dataIndex = i;
        // this.requestDetail();
      }
    },
    // 获取非当前的左侧导航样式
    getLiStyle (i) {
      const tI = this.dataIndex;
      if (i === tI) {
        return '';
      }
      const temp = Math.abs(tI - i);
      return `font-size:${32 - 3 * temp}px;opacity:${1 - 0.15 * temp};padding-left: ${3 * temp}px;`;
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.submitModel = DemoDetailModel.createAdditionModel();
        break;
      case 'download':
        this.$Message.info('点击导出');
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const resList = DemoListModel.createFakeDataList();
      this.page.total = resList.length;
      this.tableData = DemoListModel.initListWithDataList(resList);
    },
    requestDetail () {
      const resData = DemoDetailModel.createFakeData();
      this.dialog.detailModel = DemoDetailModel.initModelWithData(resData);
      SCROLL_COUNT = 0;
      this.isScrolling = false;
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
