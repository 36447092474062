<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">

</style>

<template>
  <yg-modal
    width="800"
    :title="model.id ? '编辑' : '新增'"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 300px"
    >
      1
    </div>
  </yg-modal>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {};
  },
  methods: {
    onSure () {
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close');
    }
  }
};
</script>
