export class DemoListModel extends BaseModel {
  constructor () {
    super();
    this.name = '';
    this.id = '';
    this.tel = '';
    this.mainPrice = 9999;
    this.lightPrice = 1000;
    this.province = '';
    this.city = '';
    this.area = '';
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2))
      };
      list.push(dic);
    }
    return list;
  }
}
export class DemoDetailModel extends DemoListModel {
  constructor () {
    super();
    this.idCard = ''; // 证件号码
    this.idCardType = ''; // 证件类型
    this.branchBank = ''; // 开户行--支行
    this.bankNo = ''; // 银行账号
  }

  static createFakeData () {
    const dic = this.createFakeDataList(1)[0];
    return {
      ...dic,
      ...{
        idCard: '21315465732132100',
        idCardType: '身份证',
        branchBank: '招商银行',
        bankNo: '4003894502132465789'
      }
    };
  }

  static createAdditionModel () {
    return new this();
  }
}
